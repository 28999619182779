
/* 1. Theme default css */
@import 'varriable';
@import 'mixins.scss';
body {
	font-family: $font1;
	font-weight: normal;
    font-style: normal;
    
}

.img {
	max-width: 100%;
	@include transition(.3s);
}
a,
.button {
@include transition(.3s);
}
a:focus,
.button:focus,button:focus {
	text-decoration: none;
	outline: none;
}
a:focus{
	text-decoration: none;
}
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	text-decoration: none;
}
a,
button {
	color: #1F1F1F;
	outline: medium none;
}
h1,h2,h3,h4,h5{
	font-family: $font1;
	color: #001D38;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}

ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none
}
p {
	font-size: 16px;
	font-weight:300;
	line-height: 28px;
	color: #4D4D4D;
	margin-bottom: 13px;
	font-family: $font1;
}

label {
	color: #7e7e7e;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
*::-webkit-input-placeholder {
	color: #cccccc;
	font-size: 14px;
	opacity: 1;
}
*:-ms-input-placeholder {
	color: #cccccc;
	font-size: 14px;
	opacity: 1;
}
*::-ms-input-placeholder {
	color: #cccccc;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: #cccccc;
	font-size: 14px;
	opacity: 1;
}

h3{
	font-size: 24px;
}

.mb-65{
	margin-bottom: 67px;
}
// default-bg-color
.black-bg{
	background: #020c26 !important;
}

.white-bg{
	background: #ffffff;
}
.gray-bg{
	background: #f5f5f5;
}

// background-image
.bg-img-1{
    background-image: url(../img/slider/slider-img-1.jpg);
}
.bg-img-2{
    background-image: url(../img/background-img/bg-img-2.jpg);
}
.cta-bg-1{
    background-image: url(../img/background-img/bg-img-3.jpg);

}

.overlay{
	position: relative;
	z-index: 0;
}
.overlay::before{
	position: absolute;
	content: "";
	background-color: #001D38;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: .7;
}

.overlay2{
	position: relative;
	z-index: 0;
}
.overlay2::before{
	position: absolute;
	content: "";
	background-color: #001D38;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0.6;
}

.overlay_03{
	position: relative;
	z-index: 0;
}
.overlay_03::before{
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #001D38;
	opacity: .6;
	content: '';
	z-index: -1;
}


.bradcam_overlay{
	position: relative;
	z-index: 0;
}
.bradcam_overlay::before{
	position: absolute;
	content: "";
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5db2ff+0,7db9e8+100&1+24,0+96 */
background: -moz-linear-gradient(left,  rgba(93,178,255,1) 0%, rgba(101,180,249,1) 24%, rgba(124,185,233,0) 96%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(93,178,255,1) 0%,rgba(101,180,249,1) 24%,rgba(124,185,233,0) 96%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(93,178,255,1) 0%,rgba(101,180,249,1) 24%,rgba(124,185,233,0) 96%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5db2ff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 1;
}

.section-padding{
	padding-top: 120px;
	padding-bottom: 120px;
}
.pt-120{
	padding-top: 120px;
}

/* button style */
.owl-carousel {
	.owl-nav div {
		background: transparent;
		height: 40px;
		left: 0px;
		// opacity: 0;
		position: absolute;
		text-align: center;
		top: 50%;
		-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
				transform: translateY(-50%);
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
		// visibility: hidden;
		width: 40px;
		background-color: transparent;
		@include border-radius(50%);
		left: 40px;
		font-size: 12px;
		line-height: 40px;
		background: #FFFFFF;
		left: -20px;
		color: #FF3414 !important;
	}
	.owl-nav{
		div{
			&.owl-next{
				// left: 86px;
				// right: auto;
				left: auto;
				right: -20px;
				i{
					position: relative;
					right: 0;
					// top: 1px;
				}
			}
			&.owl-prev{
				i{
					position: relative;
					// right: 1px;
					top: 0px;
				}
			}
		}
	}
	&:hover{
		.owl-nav{
			div{
				opacity: 1;
				visibility: visible;
				&:hover{
					color: #fff !important;
					background: #FF3414;
				}
			}
		}
	}
}

.mb-20px{
	margin-bottom: 20px;
}

.mb-55{
	margin-bottom: 55px;
}
.mb-40{
	margin-bottom: 40px;
}
.mb-20{
	margin-bottom: 20px;
}

.mb-50{
	margin-bottom: 50px;
}
.mb-60{
	margin-bottom: 50px !important;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	background-color: #000;
}

.custom_search_pop{
	.modal-content{
		.serch_form{
			position: relative;
			input{
				width: 100%;
				height: 50px;
				background: #fff;
				color: #fff;
				border: none;
				padding-left: 20px;
				color: #000;
				font-size: 15px;
				padding-right: 120px;
				&:placeholder{
					color: #000;
					font-size: 15px;
				}
				&:focus{
					outline: none;
				}
			}
			button{
				position: absolute;
				right: 0;
				top: 0;
				font-size: 16px;
				height: 100%;
				padding: 20px;
				color:#fff;
				background:#FF3414;
				display: inline-block;
				border: none;
				line-height: 50px;
				height: 50px;
				padding: 0 30px;
				cursor: pointer;
			}
		}
	}
}