.service_area{
    background: #F5FBFF;
    padding: 120px 0 90px 0;
    @media #{$mobile_device}{
        padding:50px 0 20px 0;
    }
    .single_service{
        margin-bottom: 30px;
        .thumb{
            overflow: hidden;
            position: relative;
            &::before{
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #FF3414;
                opacity: 0;
                position: absolute;
                z-index: 2;
                @include transition(.3s);
                content: '';
            }
            img{
                width: 100%;
                @include transform(scale(1.1));
                @include transition(.3s);
            }
        }
        .service_info{
            margin-top: 20px;
            h3{
                margin-bottom: 7px;
                a{
                    color: #001D38;
                    font-size: 22px;
                    font-weight: 500;
                }
            }
            p{
                font-size: 16px;
                font-weight: 400;
                color: #919191;  
                margin-bottom: 0;
            }
        }
        &:hover{
            .service_info{
                h3{
                    a{
                        color:#FF3414 ;
                        text-decoration: underline;
                    }
                }
            }
            .thumb{
                img{
                    @include transform(scale(1));
                }
                &::before{
                    opacity: .2;
                }
            }
        }
    }
}