
@import 'varriable.scss';
@import 'mixins.scss';
@import 'extend';
@import 'responsive.scss';
@import 'theme-default.scss';
@import 'btn';
@import 'section_title';
@import 'slick-nav.scss';



// header
@import 'header';

// slider
@import 'slider';

// about
@import 'about';

// counter
@import 'counter';

// counter
@import 'team';

// contact_quote
@import 'contact_quote';

// testmonial
@import 'testmonial';

// testmonial
@import '_service_details';

// contact_action
@import 'contact_action';

// _property_details
@import 'property_details';

// _property_details
@import '_Estimate_area';

// accordion
@import 'accordion';

// accordion_area
@import 'accordion_area';

// prise_slider
@import 'prise_slider';

// case_details
@import 'case_details';

// case_details
@import 'transport.scss';

// gallery
@import '_gallery';

// about
@import 'offers';
// about
@import 'footer';

@import 'bradcam';

@import 'department';

@import 'tesmonial';

@import 'service';

@import 'experts';

@import 'emergency_contact';


// other pages default

// contact
@import 'contact';

// elements
@import 'elements.scss';

// blog
@import 'blog';


// blog part
@import 'blog_part';

